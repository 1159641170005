/**
 * Tag post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Blockarticle from "../components/blockarticle"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Seo from "../components/seo"
import "../styles/templates/bloglist.scss"

/* Declaration function */
const TagPostTemplate = ({ data }) => {

  //Const data ACF
  const dataTag= data.wpTag

  //CTA Footer page FAQ
  var ctaFooterFAQ= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "faq"
  })

  return (
    <Layout>
      <Seo
        title={dataTag.seo.title}
        description={dataTag.seo.metaDesc}
      />
      {/* H1 header section */}
      <section className="section-hero-blog">
        <div className="hero-tag">
          <div className="title-hero-tag">
            <h1>{dataTag.name}</h1>
            <p>{dataTag.description}</p>
            <Ctabuttonwitharrow ctaLabel="Back to Blog" ctaLink="/blog" arrow="left" otherStyle="bluemarin-format" />
          </div>
        </div>
        <div className="blue-banner"></div>
      </section>

      {/* Section with loop on all articles of the author */}
      <section className="section-loop-article">
        <div className="box-loop-article">
          {data.allWpPost.edges.map(({ node }) => (
            <Blockarticle
              key={node.databaseId}
              srcImgArticle={node.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
              altImgArticle={node.acfPostBlogNews.imageArticle.altText}
              titleArticle={node.title}
              slugArticle={node.slug}
              typeSlugArticle={node.categories.nodes[0].slug}
            />
          ))}
        </div>
      </section>

      {/* Section CtaFooter */}
      {ctaFooterFAQ.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="faq" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegreyless" />
      })}
    </Layout>
  )
}

/* Export template informations */
export default TagPostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
    query($id: Int!) {
      wpTag(databaseId: {eq: $id}) {
        name
        description
        seo {
          metaDesc
          title
        }
      }
      allWpPost(filter: {tags: {nodes: {elemMatch: {databaseId: {eq: $id}}}}}, sort: {fields: [date], order:DESC}) {
        edges {
          node {
            databaseId
            title
            slug
            date (
              formatString: "DD MMMM YYYY"
            )
            categories {
              nodes {
                databaseId
                name
                slug
              }
            }
            acfPostBlogNews {
              leadArticle
              imageArticle{
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData (layout: CONSTRAINED, width: 365, formats: [AUTO], quality: 100)
                  }
                }
              }
            }
          }
        }
      }
      options: wpPage(slug: {eq: "options-website"}) {
        acfPageOptions {
          repeaterCtaFooter {
            typeCtaFooter
            titleCtaFooter
            labelCtaFooter
          }
        }
      }
    }
`
